import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'page-layout',
    component: () => import(/* webpackChunkName: "announcement" */ '../layouts/MainLayout.vue'),
    children: [
      {
        path: '/',
        name: 'announcement',
        component: () => import(/* webpackChunkName: "announcement" */ '../views/AnnouncementView.vue')
      },
      {
        path: '/download',
        name: 'download',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "download" */ '../views/DownloadView.vue')
      }

    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
